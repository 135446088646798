
.subtitle {
    font-size: 2em;
    color: blueviolet;
}

.legend{
    color: #f26f21;
    font-size: 3vw;
    font-weight: bold;
    float: unset;
    width: auto;
}


@media screen and (min-width: 800px) {
    .legend {
       font-size: 35px;
    }
}

.quadro {
   display: flex;
   align-items: center;
   justify-content: center;
   margin: auto;
   flex-direction: row;
   flex-wrap: wrap;
   width: 80%;
}

fieldset.quadro{
    border:solid;
    border-radius: 23px;
    border-color: #40c0ca;
    max-width: 1024px;
}

.figure {
    width: 30%;
}

.regist {
    font-size: 30px;
    font-weight: bold;
}

.btnRegisto {
    align-items: center;
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 25px;
    border-radius: 26px;
    width: 220px;
}

.profissional {
    background-color: #f26f21;
    margin-right: 25px;
}

.criador {
    background-color: #fbae17;
    margin-right: 25px;
}

.cardTitle {
    color: white;
    font-size: 23px;
    padding-bottom: 12px;
}

.icon {
    position: fixed;
    right: 0;
    bottom: 0;
    margin-right: 2.5%;
    margin-bottom: 2.5%;
    max-width: 50px;
}
