.IntroGoldpet{
    height: 100vh;
    background-color: #f1e9de;
    background-image: url("../../img/Monitor-Inscricao-Goldpet_Avisan.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.qrRegistoGoldpet{
    height: 20vh;
    border-radius: 10px;
    margin-bottom: 10px;
}

.bottomHome{
    max-width: 50%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,0);
}

.Logo{
    margin-top:50px;
}

.tituloHeader{
    font-size: 3vh;
    font-weight: bold;
    color: #AE9A64;
    margin-top: 65px;
    margin-bottom: 35px;
}

@media(max-width: 600px){
    .tituloHeader {
        margin-top: 85px;
    }
}

.homeLinhaCima{
    color: #AE9A64;
    margin-top: 15px;
    margin-bottom: 0;
    font-size: 2.5vh;
}

.homeLinhaBaixo{
    color: #AE9A64;
    margin-top: 0;
    font-size: 3vh;
    font-weight: bold;
}

.ImgFundo {
    width: 95%;
}
