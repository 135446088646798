.pageThankYouConsumidorFinal{
    background-color: #f1e9de;
    background-image: url("../../Fundo.jpg");
    background-repeat: repeat-y;
    background-size: cover;
    overflow: hidden;
    height: 100vh;
}

.iconCorrect {
    margin-top: 100px;
}

@media(max-width: 600px){
    .iconCorrect {
        width: 35%;
    }
}

h1.h1ThankCF{
    margin-top: 100px;
    color: #ae9a65;
    font-size: 1.2rem;
    width: 65%;
    background-color: unset;
    z-index: 2;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    opacity: 0.8;
}

.caoBottom{
    width: 70%;
    max-height: 50%;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
}

@media(max-width: 600px){
    .iconCorrect {
        width: 25%;
    }

    h1.h1ThankCF {
        margin-top: 50px;
    }

    .caoBottom {
        width: 60%;
    }
}
