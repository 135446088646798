.page{
    background-color: #40C0CA;
    overflow: hidden;
}

h1{
    margin-top: 100px;
    color:white;
    font-weight: bold;
    size: 5rem;
    background-color: #40C0CA;
    z-index: 2;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 10px;
    opacity: 0.8;
}

.caoBottom{
    max-height: 50%;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
}