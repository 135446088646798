.page {
    background-color: #40C0CA;
    z-index: 1;
    height: 100vh;
}

.overlay{
    position: fixed; /* Sit on top of the page content */
    display: block;
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
}

.formMain{
    position:absolute;
    /*width: 90%;*/
    margin: auto;
    width: 600px;
    z-index: 999999;
    margin-left: -300px;
    left: 50%;
}

@media(max-width: 600px){
    .formMain{
        width: 350px;
        margin-left: -175px;
    }
}

.formMain span{
    font-weight: bold;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    display:inline-block;
    vertical-align:middle;
}

.formMain img{
    max-height: 50px;
}

.groupLine{
    display: inline-block;
    width: 100%;
}

.formHeader{
    background-color: #fbae17!important;
    color: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
}

.formBody{
    background-color: white;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.card-body{
    background-color: white;
}

.label{
    width: 38%;
    float: left;
    text-align: left;
    padding: 0.30rem 0.30rem;
    font-size: 1rem;
    margin-top: 5px;
}

.inputform{
    width: 60%!important;
    float: right;
    margin-right: 5px;
    margin-top: 5px;
}

.inputTipoConta{
    text-align: left;
}

.inputsubmit{
    float: unset;
    margin-bottom: 20px;
    background-color: #eb6f3a!important;
    border-color: #eb6f3a;
}