.pagegp {
    background-color: #f1e9de;
    background-image: url("../../Fundo.jpg");
    background-repeat: repeat-y;
    background-size: cover;
    z-index: 1;
    height: 100vh;
}
.qrconsumidorFinal{
    margin-top: 50px;
    width: 200px;

}

h1.GTitle{
    width: 100%;
    background-color: unset!important;
    color: #ae9a65;
    margin-top: 20px;
    font-size: 25px;
}

@media (max-width: 600px){
    h1.GTitle {
        width: 100%;
    }
}

.overlaygp{
    position: fixed; /* Sit on top of the page content */
    display: block;
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
}

.formMaingp{
    position:absolute;
    /*width: 90%;*/
    margin: auto;
    width: 600px;
    z-index: 999999;
    margin-left: -300px;
    left: 50%;
    margin-top: 25%;
}

@media(max-width: 600px){
    .formMaingp{
        width: 350px;
        margin-left: -175px;
    }
}

@media(min-width: 801px){
    .formMaingp{
        margin-top: 10%;
    }
}

.formMain span{
    font-weight: bold;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    display:inline-block;
    vertical-align:middle;
}

.formMain img{
    max-height: 50px;
}

.groupLine{
    display: inline-block;
    width: 100%;
}

.formHeadergp{
    background-color: #ae9a65!important;
    color: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
}

.formBody{
    background-color: white;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.card-body{
    background-color: white;
}

.label{
    width: 38%;
    float: left;
    text-align: left;
    padding: 0.30rem 0.30rem;
    font-size: 1rem;
    margin-top: 5px;
}

.labelInline {
    width: 100%;
}

.inputform{
    margin-right: 5px;
    margin-top: 5px;
}

.inputTipoConta{
    text-align: left;
}

.inputsubmitgp{
    float: unset;
    margin-bottom: 20px;
    background-color: #ae9a65!important;
    border-color: #ae9a65;
}

.inputList {
    width: 100%;
    display: inline-flex!important;
    flex-direction: row;
    align-content: flex-start;
    margin-bottom: 15px;
}

.animals{
    width: 100%!important;
}

@media(max-width: 420px){
    .inputList{
        flex-wrap: wrap;
    }
}


